
import { defineComponent, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import PageModel4 from '@/components/page-model4/index.vue';
import { debounce } from 'lodash-es';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const search = reactive({
      current: 1,
      pageSize: 50,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        aim_org_id: current_org.id,
        record: {},
        is_active: true,
        is_search: true,
        cc_bank_account_id: null,
      },
    });
    const tableHeight = reactive({ y: window.innerHeight - 110 });
    const onresize: any = debounce(() => {
      tableHeight.y = window.innerHeight - 110;
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });

    const scgyfangan = reactive({
      title: t('银行账户'),
      column_flag: 'ccbankaccountscc',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'baseinfo-ccbankaccountscc',
      height: tableHeight.y,
      mode: 'radio',
    });

    const fangan_list = reactive({
      title: '',
      column_flag: 'scgyfanganlist',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'baseinfo-ccbanknumbercurreny',
      height: tableHeight.y + 75,
      mode: 'null',
    });

    const getFangan = (record: any) => {
      fangan_list.search.requestParams.record = { ...record };
      fangan_list.search.requestParams.cc_bank_account_id = record.id;
    };
    return {
      scgyfangan,
      fangan_list,
      tableHeight,
      getFangan,
    };
  },
  components: {
    PageModel4,
  },
});
